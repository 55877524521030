import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './styles.css';
import Button from './Button';

gsap.registerPlugin(ScrollTrigger);

function App() {
    const containerRef = useRef(null);
    const didAnimate = useRef(false);

    useEffect(() => {
        if (didAnimate.current) return;
        didAnimate.current = true;

        const paragraphs = containerRef.current.querySelectorAll('p');
        const highlights = containerRef.current.querySelectorAll('.highlight');

        gsap.fromTo(paragraphs,
            {
                opacity: 0,
                y: 300
            },
            {
                duration: 1.5,
                opacity: 1,
                y: 0,
                ease: 'power3.out',
                stagger: 0.3,
                scrollTrigger: {
                    trigger: containerRef.current,
                    start: 'top 80%',
                    end: 'bottom 50%',
                    scrub: true,
                    markers: false,
                }
            }
        );

        highlights.forEach((highlight) => {
            gsap.fromTo(highlight,
                {
                    color: '#86868b',
                    fontWeight: 500,
                },
                {
                    color: '#ffffff',
                    fontWeight: 500,
                    duration: 1,
                    ease: 'power3.out',
                    scrollTrigger: {
                        trigger: highlight,
                        start: 'top 100%',
                        end: 'bottom 20%',
                        scrub: true,
                        markers: false,
                    }
                }
            );
        });

        ScrollTrigger.create({
            trigger: containerRef.current,
            start: 'bottom bottom',
            onEnter: () => {
                gsap.to(highlights, {
                    color: '#ffffff',
                    fontWeight: 500,
                    duration: 1,
                    ease: 'power3.out'
                });
            },
            markers: false,
        });

        // Refresh ScrollTrigger after setup
        ScrollTrigger.refresh();
    }, []);

    return (
        <>
            <div className="container" ref={containerRef}>
                <h1>Waarom fiatgeld (Euro, Dollar, ...) de echte scam is</h1>
                <p><span className="highlight">Serieus, luister eens.</span> Ik ben van mening dat je het concept van bankieren niet echt begrijpt. <span className="highlight">Alle banken zijn failliet.</span> Bank Santander, Deutsche Bank, Royal Bank of Scotland, en alle overige banken. Ze zijn allemaal failliet. En waarom zijn ze failliet? Het is geen daad van God. Het is geen soort tsunami…</p>
                <p>Ze zijn failliet omdat we een systeem hebben dat <span className="highlight">fractioneel reservebankieren</span> heet, wat betekent dat banken geld kunnen uitlenen dat ze eigenlijk niet hebben. Het is een crimineel schandaal en het gaat al veel te lang door.</p>
                <p>Daarnaast hebben we te maken met <span className="highlight">moreel risico</span>, een zeer significant moreel risico vanuit de politieke sfeer. En de meeste problemen beginnen in de politiek en bij de centrale banken, die deel uitmaken van hetzelfde politieke systeem.</p>
                <p>We hebben te maken met <span className="highlight">vervalsing</span>, soms kwantitatieve versoepeling genoemd, maar vervalsing blijft vervalsing. Het kunstmatig printen van geld, wat als een gewone burger dat zou doen, diegene voor een zeer lange tijd de gevangenis in zou gaan.</p>
                <p>Toch doen regeringen en centrale banken het de hele tijd. <span className="highlight">Centrale banken onderdrukken de rente</span>, zodat we niet de echte kosten van geld hebben. En toch geven we de retailbanken de schuld van het manipuleren van LIBOR.</p>
                <p><span className="highlight">De brutaliteit hiervan is echt verbazingwekkend.</span> Het zijn de centrale banken die de rente manipuleren. En bovendien praten we nonchalant over depositogarantie. Dus als banken failliet gaan door hun eigen incompetentie en bedrog, betaalt de belastingbetaler de rekening.</p>
                <p><span className="highlight">Het is diefstal van de belastingbetaler.</span> En zolang we niet beginnen met bankiers, inclusief centrale bankiers en politici, naar de gevangenis te sturen voor deze schande, zal het doorgaan.</p>
                <Button />
            </div>
            <a href="https://bitcoin.org/en/" rel="noreferrer" target="_blank" className="btc">Buy Bitcoin.</a>
        </>
    );
}

export default App;
