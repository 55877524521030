import React from 'react';
import { ArrowRight } from 'phosphor-react';
import './styles.css';

const Button = () => {
    const handleClick = () => {
        window.open('https://www.youtube.com/watch?v=Co_tVd9gA2I', '_blank', 'noopener,noreferrer');
    };

    return (
        <div className="button-container">
            <button className="custom-button" onClick={handleClick}>
                Leer de waarheid over bankieren
                <span className="icon-container">
                    <ArrowRight size={24} weight="bold" color="#ffffff" />
                </span>
            </button>
        </div>
    );
};

export default Button;
